// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal.user_data_modal.show {
  display: flex !important;
  width: 100% !important;
}

.user_data_modal .modal-dialog {
  width: 100% !important;
  align-items: center !important;
  display: flex !important;
}

.user_data_modal .modal-dialog .modal-content {
  border-radius: 10px !important;
}

.modal-header.web_logo {
  border: 0 !important;
  justify-content: space-between !important;
}

.modal-body.user_data_modal_body {
  text-align: center;
}

.modal-header img {
  width: 100px;
}

.modal-body.user_data_modal_body button {
  margin: 20px 0;
  padding: 15px;
  font-size: 17px;
  background-color: var(--secondary-color);
  color: var(--second-cards-color);

  font-weight: 800;
}

.modal-body.user_data_modal_body span {
  font-size: 11px;
  color: var(--bs-gray-500);
}

.modal-body p.user_data_form_error {
  font-size: 14px;
  color: var(--bs-danger);
}

.active-input {
  color: var(--font-color)
}

.inactive-input {
  color: var(--sub-text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/newusermodal/newmodal.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,8BAA8B;EAC9B,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;EACpB,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,wCAAwC;EACxC,gCAAgC;;EAEhC,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE;AACF;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".modal.user_data_modal.show {\n  display: flex !important;\n  width: 100% !important;\n}\n\n.user_data_modal .modal-dialog {\n  width: 100% !important;\n  align-items: center !important;\n  display: flex !important;\n}\n\n.user_data_modal .modal-dialog .modal-content {\n  border-radius: 10px !important;\n}\n\n.modal-header.web_logo {\n  border: 0 !important;\n  justify-content: space-between !important;\n}\n\n.modal-body.user_data_modal_body {\n  text-align: center;\n}\n\n.modal-header img {\n  width: 100px;\n}\n\n.modal-body.user_data_modal_body button {\n  margin: 20px 0;\n  padding: 15px;\n  font-size: 17px;\n  background-color: var(--secondary-color);\n  color: var(--second-cards-color);\n\n  font-weight: 800;\n}\n\n.modal-body.user_data_modal_body span {\n  font-size: 11px;\n  color: var(--bs-gray-500);\n}\n\n.modal-body p.user_data_form_error {\n  font-size: 14px;\n  color: var(--bs-danger);\n}\n\n.active-input {\n  color: var(--font-color)\n}\n\n.inactive-input {\n  color: var(--sub-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
