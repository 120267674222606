// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  font-size: 20px;
  padding: 10px;
}
.modal-body {
  font-size: 20px;
}
.yes-btn {
  padding: 4px 22px;
  border-radius: 8px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: var(--body-background);
}
.no-btn {
  padding: 4px 22px;
  border-radius: 8px;
  border-radius: 10px;
  background-color: #d22b2b;
  color: var(--body-background);
}
.singleSellerPopup .modal-body .header .closeBtn {
  background-color: inherit;
}

.singleSellerPopup .modal-body .header .closeBtn svg {
  fill: var(--font-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/same-seller-popup/popup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,6BAA6B;AAC/B;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,6BAA6B;AAC/B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".modal-content {\n  font-size: 20px;\n  padding: 10px;\n}\n.modal-body {\n  font-size: 20px;\n}\n.yes-btn {\n  padding: 4px 22px;\n  border-radius: 8px;\n  border-radius: 10px;\n  background-color: var(--secondary-color);\n  color: var(--body-background);\n}\n.no-btn {\n  padding: 4px 22px;\n  border-radius: 8px;\n  border-radius: 10px;\n  background-color: #d22b2b;\n  color: var(--body-background);\n}\n.singleSellerPopup .modal-body .header .closeBtn {\n  background-color: inherit;\n}\n\n.singleSellerPopup .modal-body .header .closeBtn svg {\n  fill: var(--font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
