// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-class {
  background-color: #000000bf;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -44.5%);
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  min-height: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/components/lightbox/lightbox.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,kCAAkC;EAClC,4BAA4B;EAC5B,6BAA6B;EAC7B,iBAAiB;AACnB","sourcesContent":[".container-class {\n  background-color: #000000bf;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -44.5%);\n  max-width: calc(100% - 20px);\n  max-height: calc(100% - 20px);\n  min-height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
