// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy {
  min-height: 75vh;
}

.policy-container {
  padding: 30px;
  border-radius: 8px;
  background-color: var(--second-cards-color);
  color: var(--font-color);
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 2.155rem;
}

.policy-container h1 {
  font-size: 4rem;
  font-weight: bolder;
  text-align: center;
  background-color: var(--secondary-color);
  color: var(--bs-white);
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.policy-container h1 strong {
  color: var(--text-field-color);
}

.policy-container h2 {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 30px;
}
.policy .cover .title h3 span.active {
  color: var(--bs-white) !important;
  font-size: 2.385rem;
  font-weight: 800;
}
.policy-container.ShippingPolicy > * {
  color: var(--font-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/policy/policy.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,2CAA2C;EAC3C,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,wCAAwC;EACxC,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mCAAmC;AACrC","sourcesContent":[".policy {\n  min-height: 75vh;\n}\n\n.policy-container {\n  padding: 30px;\n  border-radius: 8px;\n  background-color: var(--second-cards-color);\n  color: var(--font-color);\n  margin-top: 30px;\n  margin-bottom: 30px;\n  font-size: 2.155rem;\n}\n\n.policy-container h1 {\n  font-size: 4rem;\n  font-weight: bolder;\n  text-align: center;\n  background-color: var(--secondary-color);\n  color: var(--bs-white);\n  padding: 5px;\n  border-radius: 8px;\n  margin-bottom: 30px;\n}\n\n.policy-container h1 strong {\n  color: var(--text-field-color);\n}\n\n.policy-container h2 {\n  font-size: 3rem;\n  font-weight: bold;\n  margin-top: 30px;\n}\n.policy .cover .title h3 span.active {\n  color: var(--bs-white) !important;\n  font-size: 2.385rem;\n  font-weight: 800;\n}\n.policy-container.ShippingPolicy > * {\n  color: var(--font-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
