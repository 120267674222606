// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addMoneyToWalletModal .modalHeading {
  font-size: 16px;
}

.addMoneyToWalletModal .moneyAmountPlaceholder {
  font-weight: 500;
  font-size: 16px;
  padding: 8px 16px;
  border: 2px solid #a5b7c4 !important;
  border-radius: 4px;
  text-align: start !important;
}

.addMoneyToWalletModal .moneyAmountPlaceholder::placeholder {
  text-align: start !important;
}

.addMoneyToWalletModal .paymentContainer {
  border: 1.5px solid #ddd;
  padding: 16px;
  gap: 16px;
  border-radius: 4px;
}

.addMoneyToWalletModal .paymentContainer .MidtransSVG {
  width: 20px;
  height: 20px;
}

.addMoneyToWalletModal .paymentContainer .PhonePeSVG {
  width: 25px;
  height: 25px;
}

.addMoneyToWalletModal .paymentContainer .cashfree-div img {
  width: 28px;
  height: 28px;
}

.addMoneyToWalletModal .payButton {
  margin-top: 10px;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: var(--secondary-color);
  color: var(--bs-white);
}

.addMoneyToWalletModal .closeModalBtn:hover {
  cursor: pointer;
}

#paymentRadioBtn {
  margin-top: 5px;
  width: 24px;
  height: 24px;
  outline: 1px solid black;
  border: 2px solid white;
}

#paymentRadioBtn:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/wallet-transaction/add-wallet-money.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,oCAAoC;EACpC,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,wCAAwC;EACxC,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".addMoneyToWalletModal .modalHeading {\n  font-size: 16px;\n}\n\n.addMoneyToWalletModal .moneyAmountPlaceholder {\n  font-weight: 500;\n  font-size: 16px;\n  padding: 8px 16px;\n  border: 2px solid #a5b7c4 !important;\n  border-radius: 4px;\n  text-align: start !important;\n}\n\n.addMoneyToWalletModal .moneyAmountPlaceholder::placeholder {\n  text-align: start !important;\n}\n\n.addMoneyToWalletModal .paymentContainer {\n  border: 1.5px solid #ddd;\n  padding: 16px;\n  gap: 16px;\n  border-radius: 4px;\n}\n\n.addMoneyToWalletModal .paymentContainer .MidtransSVG {\n  width: 20px;\n  height: 20px;\n}\n\n.addMoneyToWalletModal .paymentContainer .PhonePeSVG {\n  width: 25px;\n  height: 25px;\n}\n\n.addMoneyToWalletModal .paymentContainer .cashfree-div img {\n  width: 28px;\n  height: 28px;\n}\n\n.addMoneyToWalletModal .payButton {\n  margin-top: 10px;\n  border-radius: 4px;\n  padding: 8px 16px;\n  background-color: var(--secondary-color);\n  color: var(--bs-white);\n}\n\n.addMoneyToWalletModal .closeModalBtn:hover {\n  cursor: pointer;\n}\n\n#paymentRadioBtn {\n  margin-top: 5px;\n  width: 24px;\n  height: 24px;\n  outline: 1px solid black;\n  border: 2px solid white;\n}\n\n#paymentRadioBtn:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
