// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq {
  padding-bottom: 30px;
  min-height: 75rem;
}

.faq-container {
  padding: 30px;
  font-size: 2.435rem;
}

.faq-container .no-faq,
.faq-container .faq-card {
  background: var(--second-cards-color);
  border-radius: 8px;
}

.faq-container .no-faq {
  padding: 30px;
}

.faq-container .faq-card button {
  padding: 10px;
  background: none;
  width: 100%;
  font-weight: bolder;
  align-items: center;
  text-align: start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.faq-container .faq-card button span.icon {
  padding: 3px;
  border-radius: 5px;
  background: var(--secondary-color);
  display: none;
}

.faq-container .faq-card button span.icon.active {
  display: block;
  font-size: 1rem;
}

.faq-container .faq-card .answer {
  padding: 10px;
  width: 100%;
  font-size: 2.055rem;
  color: var(--sub-text-color);
  margin-bottom: 10px;
}

.no-faq {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-faq img {
  width: 30vw;
}

.no-faq p {
  font-size: 2.455rem;
  font-weight: bolder;
  margin-top: 20px;
}
.faqPagination > a {
  color: var(--font-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/faq/faq.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;;EAEE,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,aAAa;AACf;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,4BAA4B;EAC5B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".faq {\n  padding-bottom: 30px;\n  min-height: 75rem;\n}\n\n.faq-container {\n  padding: 30px;\n  font-size: 2.435rem;\n}\n\n.faq-container .no-faq,\n.faq-container .faq-card {\n  background: var(--second-cards-color);\n  border-radius: 8px;\n}\n\n.faq-container .no-faq {\n  padding: 30px;\n}\n\n.faq-container .faq-card button {\n  padding: 10px;\n  background: none;\n  width: 100%;\n  font-weight: bolder;\n  align-items: center;\n  text-align: start;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n\n.faq-container .faq-card button span.icon {\n  padding: 3px;\n  border-radius: 5px;\n  background: var(--secondary-color);\n  display: none;\n}\n\n.faq-container .faq-card button span.icon.active {\n  display: block;\n  font-size: 1rem;\n}\n\n.faq-container .faq-card .answer {\n  padding: 10px;\n  width: 100%;\n  font-size: 2.055rem;\n  color: var(--sub-text-color);\n  margin-bottom: 10px;\n}\n\n.no-faq {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.no-faq img {\n  width: 30vw;\n}\n\n.no-faq p {\n  font-size: 2.455rem;\n  font-weight: bolder;\n  margin-top: 20px;\n}\n.faqPagination > a {\n  color: var(--font-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
