// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-container {
  font-size: 24px;
  height: 600px;
}

.success_modal .success_body {
  font-size: 24px;
  border: 0;
  text-align: center;
}

.success_modal .success_header {
  border: 0;
}

.success_modal .success_footer {
  border: 0;
  justify-content: center;
}

.payment-container .checkout_btn {
  width: 20%;
  height: 65px;
  font-size: 18px;
  z-index: 9;
  background-color: var(--secondary-color);
  color: var(--bs-white);
  border: 0;
  font-weight: 700;
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/paypalPaymentHandler/payment-handler.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,eAAe;EACf,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;EACf,UAAU;EACV,wCAAwC;EACxC,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".payment-container {\n  font-size: 24px;\n  height: 600px;\n}\n\n.success_modal .success_body {\n  font-size: 24px;\n  border: 0;\n  text-align: center;\n}\n\n.success_modal .success_header {\n  border: 0;\n}\n\n.success_modal .success_footer {\n  border: 0;\n  justify-content: center;\n}\n\n.payment-container .checkout_btn {\n  width: 20%;\n  height: 65px;\n  font-size: 18px;\n  z-index: 9;\n  background-color: var(--secondary-color);\n  color: var(--bs-white);\n  border: 0;\n  font-weight: 700;\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
