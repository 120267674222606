// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  padding: 30px;
  min-height: 80vh;
}
.not-found-container {
  background: var(--body-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
}

.not-found-container img {
  width: 30vw;
}

.not-found-container p {
  font-size: 2.455rem;
  font-weight: bolder;
  margin-top: 20px;
  color: var(--font-color);
}

.not-found-container button {
  padding: 8px 20px;
  background: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 8px;
  font-size: 2.455rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/404/notfound.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,kCAAkC;EAClC,2BAA2B;EAC3B,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".not-found {\n  padding: 30px;\n  min-height: 80vh;\n}\n.not-found-container {\n  background: var(--body-background);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 30px;\n  border-radius: 8px;\n}\n\n.not-found-container img {\n  width: 30vw;\n}\n\n.not-found-container p {\n  font-size: 2.455rem;\n  font-weight: bolder;\n  margin-top: 20px;\n  color: var(--font-color);\n}\n\n.not-found-container button {\n  padding: 8px 20px;\n  background: var(--secondary-color);\n  color: var(--primary-color);\n  border-radius: 8px;\n  font-size: 2.455rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
