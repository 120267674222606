// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
  padding-bottom: 30px;
  min-height: 77vh;
}
.about-container {
  padding: 30px;
  border-radius: 8px;
  background-color: var(--second-cards-color);
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 2.355rem;
}

.about-container h2 {
  font-size: 4rem;
  font-weight: bolder;
  text-align: center;
  background-color: var(--secondary-color);
  padding: 5px;
  border-radius: 8px;
  color: var(--primary-color);
}

.about-container h3 {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 30px;
}

.about-container > * {
  color: var(--font-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/about/about.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,2CAA2C;EAC3C,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,wCAAwC;EACxC,YAAY;EACZ,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".about-us {\n  padding-bottom: 30px;\n  min-height: 77vh;\n}\n.about-container {\n  padding: 30px;\n  border-radius: 8px;\n  background-color: var(--second-cards-color);\n  margin-top: 30px;\n  margin-bottom: 30px;\n  font-size: 2.355rem;\n}\n\n.about-container h2 {\n  font-size: 4rem;\n  font-weight: bolder;\n  text-align: center;\n  background-color: var(--secondary-color);\n  padding: 5px;\n  border-radius: 8px;\n  color: var(--primary-color);\n}\n\n.about-container h3 {\n  font-size: 3rem;\n  font-weight: bold;\n  margin-top: 30px;\n}\n\n.about-container > * {\n  color: var(--font-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
