// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.underMaintenanceContainer {
  height: 100vh;
}
.underMaintenanceContainer .underMaintenanceText {
  font-size: 4rem;
  font-weight: 600;
  color: var(--secondary-color);
}
.underMaintenanceContainer .underMaintenanceSubText {
  font-size: 1.7rem;
  font-weight: 500;
}
.underMaintenanceContainer img {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/components/maintenance/maintenance.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,6BAA6B;AAC/B;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".underMaintenanceContainer {\n  height: 100vh;\n}\n.underMaintenanceContainer .underMaintenanceText {\n  font-size: 4rem;\n  font-weight: 600;\n  color: var(--secondary-color);\n}\n.underMaintenanceContainer .underMaintenanceSubText {\n  font-size: 1.7rem;\n  font-weight: 500;\n}\n.underMaintenanceContainer img {\n  width: 100%;\n  height: 400px;\n  margin-bottom: 20px;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
